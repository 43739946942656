export const types = {
  login: "[auth] login",
  logout: "[auth] logout",
  restore: "[auth] restore",
  register: "[auth] register",
  profession: "[auth] profession",
  uiErrorInput: "[ui] errorinput",
  modalStatus: "[ui] modalStatus",
  filterActive: "[ui] filterActive",
  whatFilterActive: "[ui] whatFilterActive",
  validationExtra: "[ui] validationExtra",
  errorFormPersonality: "[ui] errorFormPersonality",
  isFadeRegister: "[ui] isFadeRegister",
  products: "[product] getproducts",
  productsError: "[product] errorproducts",
  loading: "[product] loading",
  migajas: "[product] migajas",
  series: "[product] series",
  titlepages: "[product] titlepages",
  productview: "[product] productview",
  paginationpage: "[product] paginationpage",
  numberpagination: "[product] numberpagination",
  selecttypology: "[product] selecttypology",
  productsGeneral: "[product] productsGeneral",
  seriesupdate: "[product] seriesupdate",
  productactive: "[product] productactive",
  findActive: "[product] findActive",
  productSerie: "[product] productSerie",
  colorSelect: "[product] colorSelect",
  numberProduct: "[product] numberProduct",
};

export const initialStateReducers = {
  ui: {
    errorFormPersonality: {
      email: false,
      password: false,
    },
    errorInput: false,
    modalStatus: false,
    filterActive: false,
    validationExtra: false,
  },
};
